import React from 'react';
import Layout from '../layouts/index';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout>
        <div className="container">
          <br />
          <h1>404: Not Found</h1>
          <p>This page does not exist</p>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;
